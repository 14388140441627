import React from 'react'
import Tag from './tag'

const BASEANGLE = Math.PI / 360

const colors = ["#FF7676", "#B0C5FA", "#ACF393", "#DEE07C", "#BE73BF"]
let myInterVal: any = null;

export default class TagCloud extends React.Component<any, any> {
	constructor(props: any) {
		super(props)
		this.state = {
			speed: props.speed || .1,
			R: props.radius || 200,
			angleX: (props.speed || 1) * BASEANGLE,
			angleY: (props.speed || 1) * BASEANGLE,
			tags: [],
			timer: ''
		}
	}
	
	componentWillReceiveProps(nextProps: any) {
		if (nextProps.tagName != this.props.tagName) {

			const animation = () => {
				this.rotateX()
				this.rotateY()
				requestAnimationFrame(animation)
			}

			requestAnimationFrame(() => {
				animation()
			})

			this.move(nextProps.tagName)
		}
	}

	componentDidMount() {
		const parent = document.getElementById(this.props.parentId)
		if(parent){
			parent.addEventListener('mousemove', (e) => {
				if(myInterVal){
					clearInterval(myInterVal)
				}
				const angleX = 2 * (e.clientX / parent.getBoundingClientRect().width - 0.5) * (this.props.speed || .1) * BASEANGLE;
				const angleY = 2 * (e.clientY / parent.getBoundingClientRect().height - 0.5) * (this.props.speed || .1) * BASEANGLE;
				this.setState({
					angleX,
					angleY
				})
			})
			parent.addEventListener('mouseout', () => {
				 myInterVal = setInterval(()=>{ 
					 let angleX = this.state.angleX - 0.002
					 let angleY = this.state.angleY - 0.002
					 this.setState({
						angleX : angleX > 0 ? angleX : 0 ,
						angleY : angleY > 0 ? angleY : 0
					})
					if(angleX < 0 && angleY < 0 && myInterVal ){
						clearInterval(myInterVal)
					}
				  }, 1000);

				
			})
		}

		if (this.props.tagName.length === 0) {
			return
		}

		const animation = () => {
			this.rotateX()
			this.rotateY()
			requestAnimationFrame(animation)
		}

		requestAnimationFrame(() => {
			animation()
		})

		this.move(this.props.tagName)
	}

	handleMouseover = (e: any) => {
		const angleY = 2 * (e.clientX / document.body.getBoundingClientRect().width - 0.5) * 1 * BASEANGLE;
		const angleX = 2 * (e.clientY / document.body.getBoundingClientRect().height - 0.5) * 1 * BASEANGLE;
		this.setState({ angleX, angleY })
	}

	handleMouseout = () => {
		const angleX = this.state.speed * BASEANGLE
		const angleY = this.state.speed * BASEANGLE
		this.setState({ angleX : 0, angleY : 0 })
	}

	move(tagName: any) {
		const len = tagName.length

		const tags = tagName.map((tag: any, i: number) => {
			const angleA = Math.acos((2 * (i + 1) - 1) / len - 1)
			const angleB = angleA * Math.sqrt(len * Math.PI)

			let R = 200
			const parent = document.getElementById(this.props.parentId)
			if(parent){
				const parentwidth : Number= parent.getBoundingClientRect().width;
				if(parentwidth > 800){
					R = 200;
				}
				else{
					R = 100
				}
			}
			const z = R * Math.cos(angleA)
			const y = R * Math.sin(angleA) * Math.sin(angleB)
			const x = R * Math.sin(angleA) * Math.cos(angleB)
			const color = colors[Math.floor(Math.random() * colors.length)];

			const tagProps = {
				x,
				y,
				z,
				name: tag,
				color,
			}

			return tagProps
		})

		this.setState({ tags: tags })
	}

	rotateX() {
		let cos = Math.cos(this.state.angleX),
			sin = Math.sin(this.state.angleX);

		const tags = this.state.tags.map((tag: any) => {
			let y = tag.y * cos - tag.z * sin
			let z = tag.z * cos + tag.y * sin
			tag.y = y
			tag.z = z

			return tag
		});

		this.setState({ tags: tags })
	}

	rotateY() {
		let cos = Math.cos(this.state.angleY)
		let sin = Math.sin(this.state.angleY)

		const tags = this.state.tags.map((tag: any) => {
			let x = tag.x * cos - tag.z * sin
			let z = tag.z * cos + tag.x * sin
			tag.x = x;
			tag.z = z;

			return tag
		});

		this.setState({ tags: tags })
	}


	render() {
		const containerStyle: any = {
			heght: '100%',
			with: '100%',
			zIndex: "-2"
		}

		const wrapperStyle: any = {
			position: 'relative',
		}

		return (
			<div className="tag-cloud-container" style={containerStyle}>
				<div className="wrapper" style={wrapperStyle}>
					{this.state.tags.map((tag: any, index: number) => {
						return <Tag url={this.props.url} key={index} {...tag} theme={this.props.theme}> </Tag>
					})}
				</div>
			</div>
		)
	}
}