import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { Link } from "gatsby"
import {
  isMobileOnly
} from "react-device-detect";
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Hexagon from "../../components/ui/Hexagon"
import { Icon, Icons } from "../../components/ui/Icons"
import TagCloud from "../../components/ui/TagCloud"
import State from "../../../src/utils/global/recoil.states"

import bg1 from "../../images/bg1.jpeg"
import bg2 from "../../images/bg2.jpeg"
import bg3 from "../../images/bg3.jpeg"
import p1 from "../../images/p1.jpeg"

import SEO from "../../components/SEO"

import "./style.css"

const About = () => {
  const theme = useRecoilValue(State.theme)

  const [showtag, _showtag] = React.useState(true)

  const tagName = [
    'Javscript',
    "ReactJs",
    'ReactNative',
    'NodeJs',
    'AWS',
    'Redux',
    'Strapi',
    'GatsbyJs',
    'NextJs',
    'QuickBlox',
    "Html",
    "Css",
    "ExpressJs",
    "Strapi",
    "TypeScript",
    "ReactHooks",
    "Git",
    "ElectronJs",
    "AntDesign",
    "MaterialUi",
    "TailWind"
  ]


  const Languages = [
    { tittle: "Javascript", description:"Javascript", color: "#F7DF1E", percent: 80 , icon: <Icon icon={Icons.javascript} /> },
    { tittle: "ReactJs", description:"React JS", color: "#0089D7", percent: 90  , icon: <Icon icon={Icons.reactjs} />  },
    { tittle: "Type Script", description:"Type Script", color: "#007ACC", percent: 60  , icon: <Icon icon={Icons.typescrip} /> },
    { tittle: "NodeJS", description:"Node JS", color: "#ACF393", percent: 60  , icon: <Icon icon={Icons.nodejs} /> },
    { tittle: "NextJs", description:"Next JS", color: "#494949", percent: 70  , icon: <Icon icon={Icons.nextjs} /> },
    { tittle: "ElectronJs", description:"Electron JS",color: "#47848F", percent: 60  , icon: <Icon icon={Icons.electronjs} /> },
    { tittle: "HTML",description:"HTML", color: "#E44D26", percent: 50 , icon: <Icon icon={Icons.html} />  },
    { tittle: "CSS", description:"CSS",color: "#DBDBDB", percent: 50  , icon: <Icon icon={Icons.css} />  },
  ]

  
  return (
    <>
      <SEO title="About Me | Skills & Experience" />
      <div className="relative fontMontserrat flex justify-center items-center h-auto w-full mt-10 sm:mt-0 bg-cover bg-no-repeat  text-white flex-col" style={{backgroundImage: `url(${bg1})`}} >
        <div className="flex justify-center flex-col items-center bg-dark_bg bg-opacity-60 p-10 pt-20 pb-20 w-full">
          <div className="rounded-full w-32 h-32 md:w-48 md:h-48 border-2	 border-gray-300 border-solid	bg-cover bg-no-repeat" style={{backgroundImage: `url(${p1})`}}/>
          <span className="block w-full md:w-auto p-1  text-center mt-5 rounded-md text-2xl md:text-4xl font-extralight">A Full Stack Javascript Developer</span>
          <span className="block mt-5  p-1 rounded-md md:text-xl text-justify">
            I am a fullstack javascript developer,  with exprience in ReactJs, Typescript, NodeJs. Also i like to travel whenever i can.
          </span>
          <div className="flex flex-row justify-center mt-5">
            <OutboundLink
              target="_blank"
              href="https://www.facebook.com/Sukantash11"
              className="text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105	 duration-500"
            >
              <Icon icon={Icons.facebookIcon} />
            </OutboundLink>
            <OutboundLink
              target="_blank"
              href="https://github.com/SukantaB"
              className="text-4xl mr-4 ml-4 bg-white cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105	 duration-500"
            >
              <Icon icon={Icons.githubIcon} />
            </OutboundLink>
            <OutboundLink
              target="_blank"
              href="https://www.linkedin.com/in/sukanta-saha-64087a55/"
              className="text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105	 duration-500"
            >
              <Icon icon={Icons.linkedinIcon} />
            </OutboundLink>
            <OutboundLink
              target="_blank"
              href="https://twitter.com/sukantsh11"
              className="text-4xl mr-4 ml-4 cursor-pointer rounded-md transform  hover:-translate-y-1 hover:scale-105	 duration-500"
            >
              <Icon icon={Icons.twitterIcon} />
            </OutboundLink>
          </div>
          <Link to="/resume">
            <span
              className="block mt-6 py-2 px-3 rounded-md w-full text-center cursor-pointer  md:w-auto bg-blue-500 text-xl"
            >
              View My Resume
            </span>
          </Link>
        </div>
      </div>

      <div className={`grid grid-cols-1  ${!isMobileOnly ? "lg:grid-cols-2" : ""} gap-4 h-full fontMontserrat ${theme === "dark" ? "textLight" : "textDark"}`}>
        <div className="flex justify-center items-center h-full w-full ">
          <div className="w-full p-5 md:p-20">
            <h1 className={`font-light text-3xl md:text-5xl p-1 left-outline pageTitle transform  hover:-translate-y-1 hover:scale-105 duration-500 ${theme === "dark" ? "textLight" : "textDark"}`}> Skills & Experience </h1>
            <span className="block mt-5  p-1 rounded-md md:text-xl text-justify">
              I have one and half years of experience in webdevelopemt with Java-Script and Type-script, i have experience in working with 
              ReactJS and other ReactJS frameworks such as NextJS, GatsbyJS etc.
              <br />
              I have also worked with technoligies such as electronJs, reactNative.
             </span>
            <div className="mt-10">
              <div className="grid grid-cols-2">
                {Languages.map(el => {
                  return (
                    <>
                    <div className="flex justify-center items-center">
                      <Hexagon backgroundColor={el.color} theme={theme}>
                        <> {el.icon}</>
                      </Hexagon>
                      <div className="w-2/3 h-2/5 ">
                        <div 
                          className="w-full h-3 md:h-7 lg:h-4 xl:h-7 rounded-md" 
                          style={{ 
                              background: `linear-gradient(to right,  rgb(59, 130, 246) ${el.percent}% ,  rgb(75, 85, 99) ${100 - el.percent}%)` ,
                            }} />
                        <span className="block text-xs lg:text-base"> {el.description} </span>
                      </div>
                    </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {
          !isMobileOnly && 
            <div className={`flex justify-center items-center h-full w-full z-10`} id="tag-cloud-parent">
              <div className="flex justify-center items-center" >
                <TagCloud tagName={tagName} speed={.2} parentId="tag-cloud-parent" theme={theme}/>
              </div>
            </div>
        }
      </div>

    </>

  )
}
export default About
