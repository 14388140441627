import React from "react"
import { IHexagon } from "./model"

import "./style.css"
const colors = ["#FF7676", "#B0C5FA", "#ACF393", "#DEE07C", "#BE73BF" ]

export default (props: IHexagon) => {
  return (
    <div className="relative ml-1  mt-1 mb-1 mr-0 text-black">
      <svg  viewBox="0 0 173.20508075688772 200" className="flex justify-center items-center hexagon-svg w-11 h-11 md:w-24 md:h-24 lg:w-16 lg:h-16 xl:w-24 xl:h-24" stroke={`${props.theme ==="dark" ? "#fff" : "#000"}`} strokeWidth="4">
          <path fill={props.backgroundColor} d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"  stroke="6">
          </path>         
      </svg>
      <span className={`absolute top-1/2 left-1/2 text-xl md:text-3xl position-center  ${props.theme === "dark" ? "textLight" : "textDark"}`}>
        {props.children}
      </span>
    </div>
  )
}
